import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import fileDownload from "js-file-download";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, DOWNLOAD } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import ReportSearchCriteria from "@/view/pages/partials/Report-Search-Criteria.vue";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
export default {
  name: "report",
  mixins: [CommonMixin, ValidationMixin],
  data: function data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      paginationPageSize: 0,
      currentPage: 1,
      totalPages: 1,
      dataLoading: false,
      exportLoading: false,
      searchDialog: false,
      rowData: new Array(),
      searchableArray: new Array(),
      dates: new Array(),
      listingSearch: {
        duration: "this_week"
      },
      headerData: new Array(),
      queryParams: new Object(),
      summaryFilter: [{
        text: "This Week",
        value: "this_week"
      }, {
        text: "Previous Week",
        value: "previous_week"
      }, {
        text: "This Month",
        value: "this_month"
      }, {
        text: "Previous Month",
        value: "previous_month"
      }, {
        text: "This Quarter",
        value: "this_quarter"
      }, {
        text: "Previous Quarter",
        value: "previous_quarter"
      }, {
        text: "This Year",
        value: "this_year"
      }, {
        text: "Previous Year",
        value: "previous_year"
      }]
    };
  },
  components: {
    PhoneTextField: PhoneTextField,
    Barcode: Barcode,
    Dialog: Dialog,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingFooter: ListingFooter,
    DateRangePicker: DateRangePicker,
    ReportSearchCriteria: ReportSearchCriteria,
    ListingTemplate: ListingTemplate
  },
  methods: {
    downloadExcel: function downloadExcel() {
      var _this = this;

      _this.exportLoading = true;
      var _module = _this.$route.params.module;

      _this.$store.dispatch(DOWNLOAD, {
        url: "report/" + _module + "/export",
        data: _this.$route.query
      }).then(function (_ref) {
        var data = _ref.data;
        fileDownload(data, _module + ".xlsx");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.exportLoading = false;
      });
    },
    clearSearch: function clearSearch() {
      this.listingSearch = {
        duration: this.listingSearch.duration
      };
      this.queryParams = new Object();
      this.filterRows();
    },
    advancedSearch: function advancedSearch() {
      this.searchDialog = true;
    },
    getFilterLabel: function getFilterLabel(field) {
      var child = new Array();
      var levelOne = field.split(".");
      var parent = levelOne[0].split("_").join(" ");

      if (levelOne[1]) {
        child.push(levelOne[1].split("_").join(" "));
      }

      if (child.length) {
        var childText = child.join(" ");
        return parent + "[" + childText + "]";
      }

      return this.lodash.replace(parent, "text", "name");
    },
    filterRows: function filterRows() {
      this.listingSearch = this.lodash.pickBy(this.listingSearch, this.lodash.identity);

      if (this.lodash.isEmpty(this.listingSearch) === false) {
        this.lodash.assign(this.queryParams, this.listingSearch);
        this.pushQueryParams();
        this.searchParam = null;
        this.searchDialog = false;
        this.searchEnabled = true;
      }
    },
    setGetParams: function setGetParams() {
      var _this = this;

      _this.searchEnabled = false;

      if (_this.lodash.isEmpty(_this.$route.query) === false) {
        var allowedArray = _this.searchableArray.map(function (row) {
          return row.key;
        });

        for (var key in _this.$route.query) {
          if (_this.lodash.includes(allowedArray, key)) {
            _this.listingSearch[key] = _this.$route.query[key];
          }
        }
      }
    },
    pushQueryParams: function pushQueryParams() {
      var _this = this;

      if (!_this.lodash.isEqual(_this.$route.query, _this.queryParams)) {
        _this.$router.replace(_this.getDefaultRoute("report.detail", {
          query: _this.queryParams
        })).then(function () {
          _this.getReportData();
        }).catch(function (error) {
          _this.logError(error);
        });
      } else {
        _this.getReportData();
      }
    },
    getReportData: function getReportData() {
      var _this = this;

      _this.dataLoading = true;
      _this.rowData = new Array();
      _this.totalPages = 0;
      _this.currentPage = 0;
      _this.totalRows = 0;
      _this.rowsOffset = 0;
      var _module = _this.$route.params.module;
      var formData = new Object(_objectSpread(_objectSpread({}, _this.listingSearch), _this.$route.query));

      _this.$store.dispatch(QUERY, {
        url: "report/" + _module,
        data: formData
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.totalPages = Math.ceil(data.total_rows / data.per_page);
        _this.currentPage = data.current_page;
        _this.rowData = data.rows;
        _this.totalRows = data.total_rows;
        _this.rowsOffset = data.offset;
        _this.searchableArray = data.headers;
        _this.headerData = data.headers;
        _this.listingSearch["duration"] = formData.duration;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.dataLoading = false;

        _this.setGetParams();
      });
    }
  },
  computed: {
    isSearchEnabled: function isSearchEnabled() {
      var result = false;

      for (var i = 0; i < this.searchableArray.length; i++) {
        if (this.$route.query[this.searchableArray[i].key]) {
          if (this.lodash.isArray(this.$route.query[this.searchableArray[i].key])) {
            if (this.lodash.isEmpty(this.$route.query[this.searchableArray[i].key]) === false) {
              result = true;
              break;
            }
          } else {
            result = true;
            break;
          }
        }
      }

      return result;
    },
    showingFrom: function showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false ? this.rowsOffset + 1 : 0;
    },
    showingTo: function showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
    pageTitle: function pageTitle() {
      var title = this.$route.params.module;

      if (typeof title == "string") {
        return this.lodash.upperFirst(title.replaceAll("-", " "));
      }

      return null;
    }
  },
  mounted: function mounted() {
    this.getReportData();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Report"
    }]);
  }
};